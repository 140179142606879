import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Alert } from "./Alert";

import "../App.css";

export function Card() {

  let yellow = 'black';
  const [bgColor, setBgColor] = useState(yellow);

   const changeColorRed =(e)=>{
      e.preventDefault();
      let purple = 'red';
      setBgColor(purple);
    }
    
    const changeColorYellow =(e)=>{
      e.preventDefault();
      let purple = 'yellow';
      setBgColor(purple);
    }

  /*

  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  
  const { login, loginWithGoogle, resetPassword } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  */

  return (
    <div 
      style={{background: bgColor}}
      className="width-100">
  
      <form

      >

        <div className="center">

          <button 
            onClick={changeColorRed}

            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-4"
          >Red</button>

          <button 
            onClick={changeColorYellow}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  rounded focus:outline-none focus:shadow-outline"
          >Yellow</button>

        </div>
      </form>

    
    </div>
  );
}
